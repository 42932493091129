import React from "react";
import {
  discord,
  drone_logo,
  facebook,
  heart,
  instagram,
  twitter,
  youtube,
} from "../../assets";

const SocialIconsView = ({ className, iconClass }) => {
  const iconStyle = { width: 36, height: 36 };
  return (
    <div
      className={`d-flex justify-content-center ${className}`}
      style={{ zIndex: 4999 }}
    >
      <div className="d-flex justify-content-around align-items-center">
        <a
          // href="https://www.instagram.com/masterthesky_/"
          href="https://www.instagram.com/mastertheskygame/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={instagram}
            alt="instagram"
            className={`img-fluid mx-2 ${iconClass}`}
            style={iconStyle}
          />
        </a>
        <a
          href="https://discord.com/invite/GJVZAvJgA5"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={discord}
            alt="discord"
            className={`img-fluid mx-2 ${iconClass}`}
            style={iconStyle}
          />
        </a>
        <a
          href="https://www.facebook.com/people/Master-The-Sky/61550050755304/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={facebook}
            alt="facebook"
            className={`img-fluid mx-2 ${iconClass}`}
            style={iconStyle}
          />
        </a>
        <a
          href="https://twitter.com/MasterTheSky"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={twitter}
            alt="twitter"
            className={`img-fluid mx-2 ${iconClass}`}
            style={iconStyle}
          />
        </a>
        <a
          href="https://www.youtube.com/channel/UC6WkOycpHXCiVGO1rIcBe9w"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={youtube}
            alt="youtube"
            className={`img-fluid mx-2 ${iconClass}`}
            style={iconStyle}
          />
        </a>
      </div>
    </div>
  );
};

export default SocialIconsView;
