import React, { useEffect, useState } from "react";
import {
  drone_bg_dark,
  drone_bg_dark_mobile,
  drone_bg_extra_dark,
} from "../../assets";
import { useMediaQuery } from "react-responsive";
import Footer from "../../components/Footer/Footer";
import NewsSection from "../../sections/News/NewsSection";
import CoverSection from "../../sections/Home/CoverSection";

const Home = () => {
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });

  const [show, setShow] = useState(false);
  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  return (
    <>
      <CoverSection />
      <section
        className="w-100 bg-overlay pt-lg-5"
        style={{
          background: `url(${
            large ? drone_bg_extra_dark : drone_bg_dark_mobile
          })`,
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
          position: "relative",
          backgroundPosition: "center",
          minHeight: "100vh",
          overflow: "hidden",
        }}
      >
        <div className="content-wrapper py-4">
          <h1 className="text-center text-white text-uppercase mt-2 mb-2">
            News
          </h1>
          <NewsSection show={show} setShow={setShow} />
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Home;
