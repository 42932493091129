import React from "react";
import { useMediaQuery } from "react-responsive";

const TimelineItem = ({
  index,
  image,
  title,
  subtitle,
  content,
  published_date,
  is_special_event,
  isEven,
  className,
  onClick,
}) => {
  const msmall = useMediaQuery({ query: "(min-width: 480px)" });
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });
  const xxlarge = useMediaQuery({ query: "(min-width: 1400px)" });
  const xxxlarge = useMediaQuery({ query: "(min-width: 1600px)" });

  const styles = {
    image: {
      width: xxxlarge
        ? 272
        : xlarge
        ? 220
        : large
        ? 240
        : medium
        ? 280
        : small
        ? 260
        : 220,
      height: xxxlarge
        ? 272
        : xlarge
        ? 220
        : large
        ? 240
        : medium
        ? 280
        : small
        ? 260
        : 220,
      objectFit: "cover",
    },
    contentWrapper: {
      width: xxxlarge ? 240 : large ? 200 : medium ? 240 : small ? 220 : 190,
      height: xxxlarge ? 240 : large ? 200 : medium ? 240 : small ? 220 : 190,
      padding: xxxlarge
        ? 35
        : xlarge
        ? 28
        : large
        ? 24
        : medium
        ? 30
        : small
        ? 26
        : 22,
    },
    verticalConnector: {
      height: xxlarge
        ? 60
        : xlarge
        ? 40
        : large
        ? 60
        : medium
        ? 40
        : small
        ? 30
        : 20,
      width: 1,
      borderStyle: "dotted",
    },
    horConnector: {
      width: xlarge ? 80 : large ? 60 : medium ? 80 : small ? 62 : 40,
      height: 1,
      borderStyle: "dotted",
    },
    horConnector2: {
      width: xlarge
        ? 120
        : large
        ? 100
        : medium
        ? 300
        : small
        ? 220
        : msmall
        ? 180
        : 100,
      height: 1,
      borderStyle: "dotted",
    },
  };

  const lineMargin = xlarge ? 20 : large ? 20 : 0;

  const BottomLine = ({ show = true }) => (
    <div
      className="d-flex justify-content-center"
      style={{
        width: styles.image.width,
        marginTop: !isEven ? lineMargin : 0,
        marginBottom: isEven ? lineMargin : 0,
      }}
    >
      {/* <hr className="dotted-line-vertical" style={{ height: 100 }} /> */}
      <div
        className={show ? "border border-primary" : "border-0"}
        style={styles.verticalConnector}
      />
    </div>
  );

  return large ? (
    <div
      className={`text-center ${className}`}
      style={{
        // width: styles.image.width + styles.contentWrapper.width,
        height:
          styles.image.height + styles.verticalConnector.height + lineMargin,
        marginTop: isEven
          ? styles.image.height + styles.verticalConnector.height + lineMargin
          : 0,
        marginBottom: !isEven
          ? styles.image.height + styles.verticalConnector.height + lineMargin
          : 0,
        marginLeft: index !== 0 ? -styles.image.width * 0.4 : 0,
      }}
    >
      {isEven && <div className="bg-primary w-100" style={{ height: 2 }} />}
      {isEven && <BottomLine show={!is_special_event} />}
      {is_special_event ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            ...styles.image,
            width: styles.image.width + styles.contentWrapper.width,
          }}
          // onClick={onClick}
        >
          <div
            className="border border-primary bg-darkgreen overflow-hidden p-4"
            style={{
              width: styles.contentWrapper.width * 0.8,
              height: styles.contentWrapper.height * 0.8,
              // padding: styles.contentWrapper.padding * 0.8,
            }}
          >
            <div
              className="d-flex align-items-center text-start overflow-hidden"
              style={{
                width:
                  (styles.contentWrapper.width -
                    styles.contentWrapper.padding * 2) *
                  0.8,
                height:
                  (styles.contentWrapper.height -
                    styles.contentWrapper.padding * 2) *
                  0.8,
              }}
            >
              <p className="text-small text-white mb-3 timeline-card-title">
                <b>{title}</b>
              </p>
              {/* <div
              className="text-small text-white mb-3 max-lines-5"
              dangerouslySetInnerHTML={{
                __html: content.replace(
                  "<p>",
                  '<p class="timeline-card-content text">'
                ),
              }}
            /> */}
            </div>
          </div>
        </div>
      ) : (
        <div className={`d-flex align-items-center ${className}`}>
          <div
            className="cursor-pointer me-4"
            style={{ ...styles.image }}
            onClick={onClick}
          >
            <img
              src={image}
              alt=""
              className="img-fluid border"
              style={styles.image}
            />
          </div>
          <div
            className="border border-primary overflow-hidden"
            style={{ ...styles.contentWrapper }}
          >
            <div
              className="text-start overflow-hidden"
              style={{
                width:
                  styles.contentWrapper.width -
                  styles.contentWrapper.padding * 2,
                height:
                  styles.contentWrapper.height -
                  styles.contentWrapper.padding * 2,
              }}
            >
              <p className="text-small text-white mb-3 max-lines-3 timeline-card-title">
                <b>{title}</b>
              </p>
              <p className="text-small text-primary mb-3 max-lines-1 timeline-card-subtitle">
                {subtitle}
              </p>
              <div
                className="text-small text-white mb-3 max-lines-5"
                dangerouslySetInnerHTML={{
                  __html: content.replace(
                    "<p>",
                    '<p class="timeline-card-content text">'
                  ),
                }}
              />
            </div>
          </div>
        </div>
      )}
      {!isEven && <BottomLine show={!is_special_event} />}
      {!isEven && <div className="bg-primary w-100" style={{ height: 2 }} />}
    </div>
  ) : (
    // ----- large below
    <div
      className={`d-flex py-4 py-sm-5 border-start border-primary ${className}`}
      style={{ width: styles.image.width + styles.horConnector.width }}
    >
      <div className="d-flex flex-column">
        {!is_special_event && (
          <div className="d-flex">
            <div
              style={{
                marginTop: styles.contentWrapper.height / 2,
                marginRight: medium ? 30 : small ? 20 : 10,
              }}
            >
              <div
                className="border border-primary"
                style={styles.horConnector}
              />
            </div>

            <div
              className="border border-primary overflow-hidden mb-5"
              style={{ ...styles.contentWrapper }}
              onClick={onClick}
            >
              <div
                className="text-start overflow-hidden"
                style={{
                  width:
                    styles.contentWrapper.width -
                    styles.contentWrapper.padding * 2,
                  height:
                    styles.contentWrapper.height -
                    styles.contentWrapper.padding * 2,
                }}
              >
                <p className="text-small text-white mb-3 max-lines-3 timeline-card-title">
                  <b>{title}</b>
                </p>
                <p className="text-small text-primary mb-3 max-lines-1 timeline-card-subtitle">
                  {subtitle}
                </p>
                <div
                  className="text-small text-white mb-3 max-lines-5"
                  dangerouslySetInnerHTML={{
                    __html: content.replace(
                      "<p>",
                      '<p class="timeline-card-content text">'
                    ),
                  }}
                />
              </div>
            </div>
          </div>
        )}
        <div className={`d-flex ${is_special_event ? "mt-4" : ""}`}>
          <div
            style={{
              marginTop:
                (is_special_event
                  ? styles.contentWrapper.height
                  : styles.image.height) / 2,
              marginRight: !is_special_event
                ? medium
                  ? 30
                  : small
                  ? 20
                  : 10
                : 0,
            }}
          >
            <div
              className="border border-primary"
              style={styles.horConnector2}
            />
          </div>
          {is_special_event ? (
            <div
              className="border border-primary bg-darkgreen overflow-hidden mb-5"
              style={{ ...styles.contentWrapper }}
            >
              <div
                className="d-flex align-items-center text-start overflow-hidden"
                style={{
                  width:
                    styles.contentWrapper.width -
                    styles.contentWrapper.padding * 2,
                  height:
                    styles.contentWrapper.height -
                    styles.contentWrapper.padding * 2,
                }}
              >
                <p className="text-small text-white mb-3 timeline-card-title">
                  <b>{title}</b>
                </p>
                {/* <div
                  className="text-small text-white mb-3 max-lines-5"
                  dangerouslySetInnerHTML={{
                    __html: content.replace(
                      "<p>",
                      '<p class="timeline-card-content text">'
                    ),
                  }}
                /> */}
              </div>
            </div>
          ) : (
            <div
              style={styles.image}
              className="cursor-pointer"
              onClick={onClick}
            >
              <img
                src={image}
                alt=""
                className="img-fluid border"
                style={styles.image}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TimelineItem;
