import React from "react";
import { useMediaQuery } from "react-responsive";
import QrCodeView from "../../components/Site/QrCodeView";
import SocialIconsView from "../../components/Footer/SocialIconsView";
import ActionButtons from "./ActionButtons";

const ActionButtonsView = () => {
  const msmall = useMediaQuery({ query: "(min-width: 340px)" });
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });
  const xxlarge = useMediaQuery({ query: "(min-width: 1400px)" });

  const qrCodeHeight = xxlarge
    ? 140
    : xlarge
    ? 100
    : large
    ? 80
    : medium
    ? 106
    : small
    ? 100
    : 90;

  return (
    <div className="d-flex flex-column justify-content-end align-items-end py-5 py-lg-4 ms-auto">
      <div
        className="d-flex align-items-end"
        style={{
          width: large ? "33%" : medium ? "50%" : small ? "64%" : "100%",
        }}
      >
        <div
          className="me-2"
          // style={{ width: medium ? 480 : small ? 460 : "100%" }}
        >
          <ActionButtons
            colsXs={2}
            className="w-100"
            colClass="p-sm-2 pe-lg-0 pb-lg-0 p-xl-2"
          />
        </div>
        <QrCodeView
          className="mb-1 mb-md-0"
          imageStyle={{
            minWidth: qrCodeHeight,
            height: qrCodeHeight,
          }}
        />
      </div>
      <SocialIconsView
        className="d-none d-lg-flex mt-lg-3 mt-xl-4"
        iconClass={xxlarge ? "ms-5" : "ms-4"}
      />
    </div>
  );
};

export default ActionButtonsView;
