import React from "react";
import BackgroundWrapper from "../../components/BackgroundWrapper";
import {
  about_bg,
  drone_bg_dark_mobile,
  drone_right,
  page_dne_bg,
  page_dne_image,
} from "../../assets";
import { useMediaQuery } from "react-responsive";
import SiteNavbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { Col, Row } from "react-bootstrap";

const PageNotFound = () => {
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });

  return (
    <div>
      <BackgroundWrapper
        backgroundImage={large ? page_dne_bg : page_dne_bg}
        className="d-flex flex-column align-items-center"
      >
        <section
          className="d-flex flex-column justify-content-center align-items-center content-wrapper"
          style={{ flexGrow: 1 }}
        >
          {/* <SiteNavbar showLogo={false} title="About Us" /> */}
          <h3 className="text-white mb-4 mb-sm-5">404 page not found</h3>
          <img
            src={page_dne_image}
            alt="drone"
            className="img-fluid my-5"
            style={{ width: large ? "60%" : medium ? "75%" : "90%" }}
          />
          <h3 className="text-white mt-0 mt-3">Play for free on android </h3>
        </section>
      </BackgroundWrapper>
      <Footer />
    </div>
  );
};

export default PageNotFound;
