import React, { useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import SiteNavbar from "../../components/Navbar/Navbar";
import BackgroundWrapper from "../../components/BackgroundWrapper";
import { Button, Col, Form, Row } from "react-bootstrap";
import SiteButton from "../../components/Buttons/SiteButton";
import * as userActions from "../../Utilities/UserActions";
import { useNavigate } from "react-router-dom";
import {
  drone_bg_black,
  drone_bg_dark_mobile,
  drone_bg_extra_dark,
  drone_left,
} from "../../assets";
import Footer from "../../components/Footer/Footer";
import { SharpLine } from "../../components/Site/SharpLine";

const ContactUs = () => {
  const formRef = useRef();
  const navigate = useNavigate();
  const xsmall = useMediaQuery({ query: "(max-width: 380px)" });
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });
  const xxlarge = useMediaQuery({ query: "(min-width: 1400px)" });

  // const [name, setName] = useState("");
  // const [mobile, setMobile] = useState("");
  // const [email, setEmail] = useState("");
  // const [message, setMessage] = useState("");

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   const user = {
  //     name: name,
  //     email: email,
  //     mobile: mobile,
  //     message: message,
  //   };
  //   // console.log(user);

  //   // const response = await axios.post("", user);
  //   // if (response.) {
  //   //   toast.success("Message sent! We'll get back to you soon.");
  //   // } else {
  //   //   console.log(response);
  //   //   // toast.error("Message could not be sent! Please try again");
  //   //   toast.error("Message could not be sent! Please try again");
  //   // }
  // };

  const handleSubmit = async (e) => {
    console.log("entered");
    e.preventDefault();
    const formData = new FormData(formRef.current);
    console.log(formData);
    [].map((x) => console.log(x + ":", formData.get(x)));
    const response = await userActions.addContact(formData);
    if (response) {
      console.log(response);
      formRef.current.reset();
      navigate("/contact-us");
    }
  };

  const styles = {
    btn: {
      height: medium ? 50 : small ? 50 : xsmall ? 36 : 44,
      borderWidth: 1,
    },
  };

  return (
    <div>
      <BackgroundWrapper
        backgroundImage={large ? drone_bg_black : drone_bg_dark_mobile}
        style={{}}
      >
        <section
          className="d-flex flex-column content-wrapper h-100 scrollbar-none"
          style={{ overflowY: "scroll" }}
        >
          <SiteNavbar showLogo={false} title="Contact Us" />
          {large && (
            <h1 className="text-center text-white text-uppercase mb-5">
              Contact Us
            </h1>
          )}
          <Row xs={1} lg={2} className="mb-4">
            <Col className="d-none d-lg-inline-block p-0" lg={5} xl={6}>
              <div className="d-flex justify-content-start w-100">
                <img
                  src={drone_left}
                  alt="drone"
                  className="img-fluid"
                  style={{
                    objectFit: "contain",
                    width: xlarge
                      ? "100%"
                      : large
                      ? "100%"
                      : medium
                      ? "60%"
                      : small
                      ? "80%"
                      : "100%",
                    marginLeft: xlarge ? "-8%" : large ? "-8%" : "-4%",
                    marginTop: xlarge ? "-10%" : large ? "-8%" : "-4%",
                  }}
                />
              </div>
            </Col>
            <Col className="p-0" lg={7} xl={6}>
              {/* <p className="text-white text">
                Ready to dominate the skies and be a part of our ever-growing
                community? Connect with us now!
              </p> */}
              <div
                className="ms-auto px-2 px-sm-3 px-md-4 py-5 w-100"
                // style={{ width: large ? "55%" : "100%" }}
              >
                <Form onSubmit={handleSubmit} ref={formRef}>
                  <Form.Group className="d-lg-flex align-items-start mb-4">
                    <h4
                      className="text-center text-lg-start text-white my-2"
                      style={{ width: large ? 280 : "100%" }}
                    >
                      Name
                    </h4>
                    <Form.Control
                      type="name"
                      className="text-white p-2 rounded-0 contact-form-input border"
                      id="name"
                      placeholder=""
                      name="name"
                      // onChange={(e) => setName(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="d-lg-flex align-items-start mb-4">
                    <h4
                      className="text-center text-lg-start text-white my-2"
                      style={{ width: large ? 280 : "100%" }}
                    >
                      Email
                    </h4>
                    <Form.Control
                      type="email"
                      className="text-white p-2 rounded-0 contact-form-input border"
                      id="email"
                      placeholder=""
                      name="email"
                      // onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="d-lg-flex align-items-start mb-4">
                    <h4
                      className="text-center text-lg-start text-white my-2"
                      style={{ width: large ? 280 : "100%" }}
                    >
                      Phone
                    </h4>
                    <Form.Control
                      type="mobile"
                      className="text-white p-2 rounded-0 contact-form-input border"
                      id="mobile"
                      placeholder=""
                      name="mobile"
                      // onChange={(e) => setMobile(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="d-lg-flex align-items-start mb-4">
                    <h4
                      className="text-center text-lg-start text-white my-2"
                      style={{ width: large ? 280 : "100%" }}
                    >
                      Message
                    </h4>
                    <Form.Control
                      as="textarea"
                      className="text-white p-2 rounded-0 contact-form-input border"
                      id="message"
                      rows="4"
                      name="message"
                      // onChange={(e) => setMessage(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                  <div className="d-flex">
                    {large && <div style={{ width: large ? 270 : "100%" }} />}
                    <div className="w-100 border-secondary text-center text-lg-start">
                      <Button
                        type="submit"
                        className="border-0 w-100 rounded-0 bg-secondary"
                        style={{ minWidth: 150, minHeight: 50 }}
                      >
                        Send
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
          <div
            className={`d-flex flex-column align-items-center mt-5 mb-4 pb-2 ${
              xxlarge ? "py-0" : large ? "py-5" : "pb-2"
            }`}
            // style={{ marginTop: xxlarge ? -50 : 0 }}
          >
            <div className="mb-4">
              <SharpLine className="w-100 mb-5" style={{ height: 1 }} />
              <h4 className="ms-4 text-uppercase text-white text-center mb-2 cursor-pointer pt-2 pb-1">
                Ready to dominate the skies and be a part of our ever-growing
                community? Connect with us now!
              </h4>
            </div>
            <Button
              href="https://discord.com/invite/GJVZAvJgA5"
              target="_blank"
              className="d-flex align-items-center bg-transparent border-white p-2 px-md-4 mb-2 rounded-0 text-bold mx-auto"
              style={styles.btn}
            >
              <b>Join Our Community</b>
            </Button>
          </div>
        </section>
      </BackgroundWrapper>
      <Footer />
    </div>
  );
};

export default ContactUs;
