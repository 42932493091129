import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.scss";
import Routers from "./Routers";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return <Routers />;
}

export default App;
