import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import BackgroundWrapper from "../../components/BackgroundWrapper";
import { drone_bg_dark, drone_bg_dark_mobile } from "../../assets";
import SiteNavbar from "../../components/Navbar/Navbar";
import * as userActions from "../../Utilities/UserActions";
import Loader from "../../components/Site/Loader";
import Footer from "../../components/Footer/Footer";
import WallpaperCard from "../../components/Wallpapers/WallpaperCard";

const Wallpapers = () => {
  const xsmall = useMediaQuery({ query: "(max-width: 380px)" });
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });

  // ----- wallpapers -----
  const [wallpapersData, setWallpapersData] = useState([]);

  const getWallpapers = async () => {
    const response = await userActions.fetchWallpapers();
    if (response) {
      setWallpapersData(response);
    } else {
      console.log("Error in loading blogs", "error");
    }
  };

  useEffect(() => {
    getWallpapers();
  }, []);

  return (
    <div>
      <BackgroundWrapper
        backgroundImage={large ? drone_bg_dark : drone_bg_dark_mobile}
      >
        <section
          className="d-flex flex-column content-wrapper scrollbar-none h-100"
          style={{ overflowY: "scroll" }}
        >
          <SiteNavbar showLogo={false} title="Wallpapers" />
          <div>
            {large && (
              <h1 className="text-center text-white text-uppercase mb-4">
                Wallpapers
              </h1>
            )}
            {(large || xlarge) && <h2 className="text-center text-white text-uppercase mb-5">
              Hover over an image to download
            </h2>}
          </div>
          <div className="d-flex flex-column align-items-center">
            {wallpapersData.length > 0 ? (
              wallpapersData?.map((item, i) => (
                <WallpaperCard key={i} item={item} />
              ))
            ) : (
              <Loader />
            )}
          </div>
        </section>
      </BackgroundWrapper>
      <Footer />
    </div>
  );
};

export default Wallpapers;
