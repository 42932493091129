import React from "react";

const BackgroundWrapper = ({ children, backgroundImage, className, style }) => {
  return (
    <section
      className={`w-100 bg-overlay ${className}`}
      style={{
        background: `url(${backgroundImage})`,
        // backgroundSize: "100% 100%",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        position: "relative",
        backgroundPosition: "center center",
        // background: "linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1))",
        minHeight: "100vh",
        overflow: "hidden",
        ...style,
      }}
    >
      {children}
    </section>
  );
};

export const BackgroundImageWrapper = ({
  children,
  backgroundImage,
  overlay,
  className,
  style,
}) => {
  return (
    <section class="showcase text-center position-relative overflow-hidden">
      <img
        src={backgroundImage}
        alt="showcase"
        // className="position-absolute top-0 start-0"
      />
      <div
        className={`overlay-container position-absolute top-0 start-0 ${
          overlay ? "overlay" : "overlay-0"
        }`}
      >
        <div
          className={className}
          style={{ width: "100vw", height: "100vh", ...style }}
        >
          {children}
        </div>
      </div>
    </section>
  );
};

export default BackgroundWrapper;
