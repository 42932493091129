import React, { useEffect, useRef, useState } from "react";
import {
  arrow_left,
  arrow_right,
  back_icon_circle,
  cursor_hand,
  double_chevron_left,
  double_chevron_right,
  drone_bg_dark,
  drone_bg_dark_mobile,
} from "../../assets";
import BackgroundWrapper from "../../components/BackgroundWrapper";
import { useMediaQuery } from "react-responsive";
import SiteNavbar from "../../components/Navbar/Navbar";
import TimelineItem from "../../components/Timeline/TimelineItem";
import { Modal } from "react-bootstrap";
import { SharpLine } from "../../components/Site/SharpLine";
import TimelineCarouselCard from "../../components/Timeline/TimelineCarouselCard";
import * as userActions from "../../Utilities/UserActions";
import Loader from "../../components/Site/Loader";
import Footer from "../../components/Footer/Footer";
import { formatDate } from "../../sections/News/NewsSection";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import Slider from "react-slick/lib/slider";

const Timeline = () => {
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });

  const sliderRef = useRef(null);

  const [timelineData, setTimelineData] = useState([]);
  const [timelineCarouselData, setTimelineCarouselData] = useState([]);
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [show, setShow] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [totalCarouselItems, setTotalCarouselItems] = useState(0);

  const getLatestNews = async () => {
    const res = await userActions.fetchNewsForTimeline();
    if (res) {
      console.log(res);
      setTimelineData(res);
      const filteredRes = res.filter((item) => !item.is_special_event);
      setTimelineCarouselData(filteredRes);
    } else {
      console.log("Error in loading latest updates");
    }
  };

  const onClickPrev = () => {
    if (currentIndex !== 0) {
      setCurrentIndex(currentIndex - 1);
    } else {
      setCurrentIndex(totalCarouselItems - 1);
    }
  };

  const onClickNext = () => {
    if (currentIndex !== totalCarouselItems - 1) {
      setCurrentIndex(currentIndex + 1);
    } else {
      setCurrentIndex(0);
    }
  };

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: large,
    initialSlide: currentIndex,
    afterChange: (index) => {
      setCurrentIndex(index);
    },
    prevArrow: (
      <img
        src={double_chevron_left}
        alt="Prev"
        className="img-fluid"
        onClick={onClickPrev}
      />
    ),
    nextArrow: (
      <img
        src={double_chevron_right}
        alt="Next"
        className="img-fluid"
        onClick={onClickNext}
      />
    ),
  };

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  // useEffect(() => console.log(screenSize), [screenSize]);

  useEffect(() => {
    if (timelineData) setTotalCarouselItems(timelineData.length);
  }, [timelineData]);

  useEffect(() => {
    getLatestNews();
  }, []);

  useEffect(() => {
    console.log(show);
  }, [show]);

  const styles = {
    newsImageStyle: {
      objectFit: "cover",
      height: xlarge
        ? "40vh"
        : large
          ? "30vh"
          : medium
            ? "40vh"
            : small
              ? "40vh"
              : "40vh",
      // height: xlarge ? 260  : large  ? 200  : medium  ? 400  : small  ? 280  : 220,
    },
    carouselSharpLinesStyle: {
      width: xlarge ? 280 : medium ? 260 : small ? 200 : 120,
    },
  };

  // var count = 0;

  var isPanning = false;
  var startX;
  var scrollLeft;

  var child = document.getElementById("child");

  child?.addEventListener("mousedown", function (e) {
    isPanning = true;
    startX = e.pageX - child.offsetLeft;
    scrollLeft = child.scrollLeft;
    child.style.cursor = 'grabbing';
  });

  child?.addEventListener("mousemove", function (e) {
    if (!isPanning) return;
    var x = e.pageX - child.offsetLeft;
    var walk = (x - startX) * 3; // Adjust the multiplier for sensitivity
    child.scrollLeft = scrollLeft - walk;
  });

  child?.addEventListener("mouseup", function () {
    isPanning = false;
    child.style.cursor = 'default';
  });

  child?.addEventListener("mouseleave", function () {
    isPanning = false;
  });

  return (
    <div>
      <BackgroundWrapper
        backgroundImage={large ? drone_bg_dark : drone_bg_dark_mobile}
        overlay={true}
      >
        <section className="mb-5 position-relative">
          <div className="content-wrapper">
            <SiteNavbar
              showLogo={false}
              showTitleOnLg={true}
              title="Timeline"
            />
            <div>
              {large ? (
                <div
                  id="parent"
                  className="overflow-hidden w-100"
                  style={{
                    height: xlarge
                      ? screenSize.height * 0.89
                      : screenSize.height * 0.9,
                  }}
                >
                  <div
                    id="child"
                    className="d-flex align-items-center flex-lg-row scrollbar-none mx-auto pb-5"
                    style={{
                      height: xlarge
                        ? screenSize.height * 0.89
                        : screenSize.height * 0.9,
                      overflowX: "scroll",
                    }}
                  >
                    {timelineData.length > 0 ? (
                      timelineData?.map((item, index) => {
                        // if (item.is_special_even) count += 1;
                        return (
                          <TimelineItem
                            key={index}
                            index={index}
                            title={item.title}
                            image={item.news_image}
                            subtitle={item?.subtitle}
                            content={item.content}
                            // published_date={item?.published_date}
                            published_date={formatDate(item?.published_date)}
                            isEven={index % 2 !== 0}
                            is_special_event={item.is_special_event}
                            onClick={() => {
                              setCurrentIndex(index);
                              setShow(true);
                            }}
                          />
                        );
                      })
                    ) : (
                      <Loader />
                    )}
                  </div>
                </div>
              ) : (
                <div
                  className="d-flex flex-column scrollbar-none p-4 p-sm-5"
                  style={{
                    width: screenSize.width,
                    // height: screenSize.height,
                    overflowY: "scroll",
                  }}
                >
                  {timelineData.length > 0 ? (
                    timelineData?.map((item, i) => {
                      return (
                        <TimelineItem
                          key={i}
                          title={item.title}
                          image={item.news_image}
                          subtitle={item?.subtitle}
                          content={item.content}
                          published_date={item?.published_date}
                          isEven={i % 2 !== 0}
                          is_special_event={item.is_special_event}
                          onClick={() => {
                            setCurrentIndex(i);
                            setShow(true);
                          }}
                        />
                      );
                    })
                  ) : (
                    <Loader />
                  )}
                </div>
              )}
            </div>
          </div>
          <div
            className="d-none d-lg-block position-absolute"
            style={{ width: screenSize.width, bottom: -20 }}
          >
            <div
              className="d-flex justify-content-center align-items-center w-100"
              style={{ height: 80 }}
            >
              <SharpLine toLeft={true} style={styles.carouselSharpLinesStyle} />
              <div className="mx-4">
                <img src={arrow_left} alt="left" className="img-fluid" />
                <img
                  src={cursor_hand}
                  alt="cursor"
                  className="img-fluid mx-4 cursor-pointer"
                />
                <img src={arrow_right} alt="right" className="img-fluid" />
              </div>
              <SharpLine
                toRight={true}
                style={styles.carouselSharpLinesStyle}
              />
            </div>
          </div>
        </section>
        <Modal
          show={show}
          onHide={() => setShow(false)}
          animation={false}
          fullscreen
          dialogClassName="custom-modal"
          style={{ zIndex: 4999 }}
        >
          <BackgroundWrapper
            backgroundImage={drone_bg_dark}
            className="d-flex flex-column"
            style={{ height: "100vh" }}
          >
            <Modal.Header closeButton className="border-0">
              <div
                className="ms-2 ms-md-3 ms-lg-5 me-3 mt-2 mt-md-3 mt-lg-4 cursor-pointer"
                onClick={() => setShow(false)}
              >
                <img
                  src={back_icon_circle}
                  alt="back"
                  className="img-fluid"
                  style={{ width: 28 }}
                />
              </div>
              {!large && (
                <div className="d-flex d-lg-none align-items-center mt-2">
                  <h2 className="text-white me-4">Timeline</h2>
                  <SharpLine
                    toRight={true}
                    style={{ width: medium ? 300 : small ? 250 : 120 }}
                  />
                </div>
              )}
            </Modal.Header>
            <Modal.Body className="position-relative flex-grow-1 pt-lg-0 scrollbar-nones">
              <div className="d-flex flex-column mx-4 flex-grow-1">
                <div className="mb-5 flex-grow-1">
                  {/* {timelineData.length > 0 ? (
                    timelineData
                      ?.filter((_, index) => index === currentIndex)
                      .map((item, i) => {
                        return (
                          <TimelineCarouselCard
                            key={i}
                            title={item.title}
                            image={item.news_image}
                            altTag={item?.alt_tag}
                            content={item.content}
                            onClickPrev={onClickPrev}
                            onClickNext={onClickNext}
                          />
                        );
                      })
                  ) : (
                    <Loader />
                  )} */}
                  {timelineCarouselData.length > 0 ? (
                    <Slider
                      ref={sliderRef}
                      {...settings}
                      className="d-flex flex-grow-1"
                    >
                      {timelineCarouselData.map((item, i) => (
                        <TimelineCarouselCard
                          key={i}
                          title={item.title}
                          image={item.news_image}
                          content={item.content}
                          onClickPrev={onClickPrev}
                          onClickNext={onClickNext}
                        />
                      ))}
                    </Slider>
                  ) : (
                    <Loader />
                  )}
                </div>
              </div>
            </Modal.Body>
          </BackgroundWrapper>
        </Modal>
      </BackgroundWrapper>
      <Footer />
    </div>
  );
};

export default Timeline;
