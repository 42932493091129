import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";
import ContactUs from "./pages/ContactUs/ContactUs";
import Wallpapers from "./pages/Wallpapers/Wallpapers";
import Timeline from "./pages/Timeline/Timeline";
import AboutUs from "./pages/AboutUs/AboutUs";
import News from "./pages/News/News";
import UserPrivacyPolicy from "./pages/PrivacyPolicy/UserPrivacyPolicy";
import PageNotFound from "./pages/Error/PageNotFound";
import { Flip, ToastContainer } from "react-toastify";
import { Container } from "react-bootstrap";

const Routers = () => {
  return (
    <Router>
      <Container fluid className="px-0">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
          transition={Flip}
        />
        <Routes>
          <Route path="" element={<Home />} />
          <Route path="updates" element={<News />} />
          <Route path="about-us" element={<AboutUs />} />
          <Route path="timeline" element={<Timeline />} />
          <Route path="wallpapers" element={<Wallpapers />} />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="user-privacy-policy" element={<UserPrivacyPolicy />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Container>
    </Router>
  );
};

export default Routers;
