import React, { useState } from "react";
import {
  double_chevron_down,
  home_cover_bg,
  home_cover_bg_mobile,
  play_button_gif,
  play_button_png,
  play_button,
  play_button_new,
} from "../../assets";
import { useMediaQuery } from "react-responsive";
import SiteNavbar from "../../components/Navbar/Navbar";
import HomeVideoContainer from "../../components/Home/HomeVideoContainer";
import BackgroundWrapper from "../../components/BackgroundWrapper";
import ActionButtonsView from "../../sections/Home/ActionButtonsView";
import { AiOutlineClose } from "react-icons/ai";

const CoverSection = () => {
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });
  const xxlarge = useMediaQuery({ query: "(min-width: 1400px)" });

  const [playGif, setPlayGif] = useState(false);
  const [showVideo, setShowVideo] = useState(false);

  return (
    <BackgroundWrapper
      backgroundImage={large ? home_cover_bg : home_cover_bg_mobile}
    >
      <section style={{ overflowX: "hidden" }}>
        <div className="content-wrapper pt-md-3 pt-lg-4">
          <SiteNavbar showVideo={showVideo} />
        </div>
        <div className="content-wrapper position-absolute top-0 w-100">
          <div
            className="d-flex justify-content-end align-items-center w-100 p-3 position-relative"
            style={{ height: "100vh", width: "100vw" }}
          >
            <div className="w-100 text-center">
              {!playGif ? (
                <img
                  src={play_button_png}
                  alt="play button"
                  className="img-fluid mx-auto cursor-pointer"
                  style={{
                    width: large
                      ? "36%"
                      : medium
                      ? "65%"
                      : small
                      ? "85%"
                      : "100%",
                  }}
                  onClick={() => setShowVideo(true)}
                  onMouseEnter={() => setPlayGif(true)}
                  onMouseLeave={() => setPlayGif(false)}
                />
              ) : (
                <img
                  src={play_button_gif}
                  alt="play button"
                  className="img-fluid mx-auto cursor-pointer"
                  style={{
                    width: large
                      ? "36%"
                      : medium
                      ? "65%"
                      : small
                      ? "85%"
                      : "100%",
                  }}
                  onClick={() => setShowVideo(true)}
                  onMouseEnter={() => setPlayGif(true)}
                  onMouseLeave={() => setPlayGif(false)}
                />
              )}
            </div>
          </div>
        </div>
        <div className="position-absolute bottom-0 end-0 w-100">
          <div className="content-wrapper w-100">
            <ActionButtonsView />
          </div>
        </div>
        <div
          className="position-absolute w-100 text-center"
          style={{ bottom: 20 }}
        >
          <img
            src={double_chevron_down}
            alt="chevron down"
            className="img-fluid"
            style={{ width: 20, bottom: 20 }}
          />
        </div>
        {showVideo && (
          <div
            className="w-100 position-fixed fixed-top"
            style={styles.modalContainer}
          >
            <HomeVideoContainer setShowVideo={setShowVideo} />
          </div>
        )}
      </section>
    </BackgroundWrapper>
  );
};

const styles = {
  modalContainer: {
    zIndex: 1000,
    backgroundColor: "rgba(50, 50, 50, 0.1)",
    top: 0,
    height: "100vh",
  },
};

export default CoverSection;
