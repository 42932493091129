import React from "react";
import { useMediaQuery } from "react-responsive";
import BackgroundWrapper from "../../components/BackgroundWrapper";
import { drone_bg_dark_mobile, privacy_policy_bg } from "../../assets";
import SiteNavbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";

const UserPrivacyPolicy = () => {
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });

  return (
    <BackgroundWrapper
      backgroundImage={large ? privacy_policy_bg : drone_bg_dark_mobile}
      className="d-flex flex-column align-items-center"
      style={{ height: "100vh" }}
    >
      <section
        className="w-100 content-wrapper scrollbar-none"
        style={{ overflowY: "scroll", flexGrow: 1 }}
      >
        <div className="py-4">
          <SiteNavbar title="User Privacy Policy" />
        </div>
        <div>
          <div className="mb-5">
            <h1 className="text-white text-bold mb-4 ppheaders">Privacy Policy</h1>
            <p className="text-white text fw-normal">
              This Privacy Policy (“Policy”) provides for Pastelcube
              Technologies Private Limited’s (“Master The Sky - Drones Unleashed”
              “we” “our” or “us”) policy regarding the collection, sharing,
              and use of information provided by you (“user” “you” “your”).
              By continuing the use of our website services (“Website”)
              you signify your acceptance of this Policy.
            </p>
          </div>
          <div className="mb-5">
            <h2 className="text-white text-bold mb-4 ppheaders">Information We Collect</h2>
            <p className="text-white text fw-normal">
              While some parts of the Website allow you to visit as a guest
              and remain anonymous, restricting your access to certain content
              and features of the website, you may need to provide us with
              certain information for registering on the Website. For instance,
              you may need to provide us with information including but not
              limited to name, birth date, profile name, payment-related information,
              phone number, password, email, zip/postal code, and other similar
              information. Once you give us your personal information, you
              are not anonymous to us. Where possible, we indicate which
              fields are required and which fields are optional.
              <br />
              The post messages on our message boards, personalized messages,
              images, photos, gift card message boxes, chat rooms, or other
              message areas or leave feedback/product reviews on the website,
              are collected by us. Please note such messages posted by you
              in the public domain and can ```````be read by others as well,
              please exercise caution while posting such messages, personal
              details, photos, and reviews. We retain this information as
              necessary to resolve disputes, provide customer support, internal
              research and troubleshoot problems as permitted by law. If you
              send us personal correspondence, such as emails or letters, or
              if other users or third parties send us correspondence about
              your activities or postings on the website, we may collect
              such information into a file specific to you.
            </p>
          </div>
          <div className="mb-5">
            <h3 className="text-white text-bold mb-4 ppheaders">
              Information We Collect Automatically
            </h3>
            <p className="text-white text fw-normal">
              We collect some information automatically when you visit, access,
              or use the Website. This includes information about the
              application usage, user interactions, purchases, and other
              activity on the Website, typically associated with your account
              profile or with an identifier we have assigned to your device or
              profile. Our primary goal in doing this is to provide you with a
              safe, efficient, smooth, and customized experience. This allows
              us to provide services and features that most likely meet your
              needs and to customize our website to make your experience safer and easier.
              <br />
              While the specific types of information that we automatically
              collect may vary, they generally include:
            </p>
            <ul>
              <li className="text-white text fw-normal">
                Usage information and statistics about how you interact with the
                Website, including the application you used, saved preferences,
                crash reports, and navigation data (like the URLs of our websites
                that you have visited, URLs of referring and exiting pages,
                page views, time spent on a page);
              </li>
              <li className="text-white text fw-normal">
                Technical information about your computer, device, hardware,
                or software you use to access our website, such as IP address,
                device identifiers, your internet service provider, plugins,
                or other transactional or identifier information for your device
                (such as device make and model, information about device operating
                systems and browsers, or other device or system-related specifications); and
              </li>
              <li className="text-white text fw-normal">
                The general location of your device, which we typically
                derive from your device's IP address.
              </li>
            </ul>
          </div>
          <div className="mb-5">
            <p className="text-white text-bold mb-4">Third Party Websites</p>
            <p className="text-white text fw-normal">
              You understand that the Website may contain links to other websites that
              we do not operate. If you click on a third-party link, you will
              be directed to that third-party's site. We encourage you to review
              the privacy policy of every site you visit. We shall not be responsible
              for the third-party business partner's privacy practices or the content
              of their privacy policies, and we request you read their privacy
              policies prior to disclosing any information.
            </p>
          </div>
          <div className="mb-5">
            <p className="text-white text-bold mb-4">Use</p>
            <p className="text-white text fw-normal">
              We use personal information to provide the services you request. We
              use your personal information to assist users and business partners
              in handling and fulfilling orders; enhancing customer experience;
              resolving disputes; troubleshooting problems; helping promote a safe
              service; collecting money; measuring consumer interest in our products
              and services, informing you about online and offline offers, products,
              services, and updates; customize your experience; detect and protect
              us against error, fraud and other criminal activity; enforce our terms
              and conditions; conduct marketing research, analysis and surveys; and
              as otherwise described to you at the time of collection of information.
              We will ask for your permission to allow us access to your text messages
              (SMS), instant messages, contacts in your directory, camera, photo
              gallery, location, and device information:
              <br />
              (i) to send commercial communication regarding your orders or other
              products and services
              <br />
              (ii) enhance your experience on the website and provide you access
              to the products and services offered on the Website by users, affiliates,
              partners, or lending partners. You understand that your access to these
              products/services may be affected in the event permission is not provided
              to us. In our efforts to continually improve our product and service
              offerings, we collect and analyze demographic and profile data about
              users' activity on our website. We identify and use your IP address to
              help diagnose problems with our server, and to administer our website.
              Your IP address is also used to help identify you and to gather broad
              demographic information. We will occasionally ask you to complete surveys
              conducted either by us or through a third-party market research agency.
              <br />
              <br />
              We may also process information that does not identify you individually,
              including aggregate or de-identified information that we create or
              collect from other sources. This information helps us understand larger
              groups of users better. If we combine this information with information
              that identifies you, we shall treat it as described in this Policy. Please
              note that this Policy does not restrict our ability to process information
              that does not individually identify you, and we may use and disclose
              aggregated or de-identified information for any reason permitted by law.
            </p>
          </div>
          <div className="mb-5">
            <h4 className="text-white text-bold mb-4 ppheaders">
              Recipients of Your Information
            </h4>
            <p className="text-white text fw-normal">
              We may share your information for business purposes with third-party
              entities including but not limited to:
            </p>
            <ul>
              <li className="text-white text fw-normal">
                Our affiliates, business partners, suppliers, and sub-contractors
                for the performance of any contract we enter into with them or you,
                for example for hosting and operating the website or providing
                support and maintenance services for the website;
              </li>
              <li className="text-white text fw-normal">
                Analytics and search engine providers and other selected third parties
                that assist us in the improvement and optimization of our website;
              </li>
              <li className="text-white text fw-normal">
                Our regulators, law enforcement, or fraud prevention agencies, as well
                as our advisors (including legal advisors and auditors), courts, and
                any other authorized bodies, for the purposes of investigating any
                actual or suspected criminal activity or other regulatory or legal matters;
              </li>
              <li className="text-white text fw-normal">
                In the event of restructuring or sale of all or a significant part of our business;
              </li>
              <li className="text-white text fw-normal">
                To protect the rights, property, or safety of Master The Sky - Drones Unleashed, our customers, or others.
              </li>
            </ul>
          </div>
          <div className="mb-5">
            <h5 className="text-white text-bold mb-4 ppheaders">Privacy Settings</h5>
            <p className="text-white text fw-normal">
              Depending on your interaction with the Website, we seek to
              provide you with meaningful choices about the personal data
              we collect. The specific choices available to you often
              vary depending on how you interact with the Website:
            </p>
            <ul>
              <li className="text-white text fw-normal">
                If we process your personal data based on your consent,
                you have the right to withdraw your consent at any time by
                updating the settings in your user profile or contacting
                us at talk2us@pastelcube.com.
              </li>
              <li className="text-white text fw-normal">
                You can also change your email marketing preferences at any
                time by using the opt-out mechanism provided in our marketing emails.
              </li>
              <li className="text-white text fw-normal">
                If you wish to modify any of your user profile information,
                restrict the processing of your personal data, or cancel your
                account, you may update the settings in your user profile or
                contact us at talk2us@pastelcube.com.
              </li>
              <li className="text-white text fw-normal">
                You can request that we provide access to, or that we correct
                or delete, personal information we've collected from you.
                Please submit requests to access, update, or delete personal
                information associated with your Master The Sky - Drones
                Unleashed account by contacting us at talk2us@pastelcube.com.
                Note that we may ask you for additional information to help
                us verify who you are before completing your request.
              </li>
              <li className="text-white text fw-normal">
                You can change your browser or mobile device settings to block,
                manage, delete, or limit tracking technologies like cookies.
                In some cases, blocking or disabling cookies may cause the
                Website not to work as intended and some features may not be available.
              </li>
            </ul>
          </div>
          <div className="mb-5">
            <h6 className="text-white text-bold mb-4 ppheaders">
              Security of the Personal Information
            </h6>
            <p className="text-white text fw-normal">
              While we take precautions against all possible security breaches
              on our website, Consequently, we cannot guarantee that the website
              shall be free from unauthorized access, hacking, data loss, or
              other breaches. We urge you to take steps to keep your personal
              information safe by memorizing your password or storing it safely
              (separate from your account information), logging out of your
              user account, keeping your OTP secret, and closing your web browser
              whenever you finish your session or leave your computer in a public area.
              <br />
              We employ a security technology known as a Secure Sockets Layer
              (SSL) protocol to protect the transmission of critical account information.
            </p>
          </div>
          <div className="mb-5">
            <p className="text-white text-bold mb-4">Disclaimer</p>
            <p className="text-white text fw-normal">
              YOU ARE ADVISED TO REVIEW THIS PRIVACY POLICY CAREFULLY SO THAT YOU
              UNDERSTAND OUR PRIVACY PRACTICES. BY USING OUR WEBSITE, YOU WILL BE
              DEEMED TO HAVE READ, UNDERSTOOD, AND AGREED TO THE PRACTICES AND POLICIES
              OUTLINED IN THIS PRIVACY POLICY AND AGREE TO BE BOUND BY THE PRIVACY
              POLICY. YOU HEREBY CONSENT TO OUR COLLECTION, USE, STORING AND SHARING
              OF YOUR PERSONAL INFORMATION AS DESCRIBED IN THIS PRIVACY POLICY. IF
              YOU USE THE SERVICES ON BEHALF OF SOMEONE ELSE, YOU REPRESENT THAT
              YOU ARE AUTHORISED BY SUCH INDIVIDUAL OR ARE LEGALLY AUTHORIZED TO
              (I) ACCEPT THIS PRIVACY POLICY ON SUCH INDIVIDUAL'S BEHALF, AND (II)
              CONSENT ON BEHALF OF SUCH INDIVIDUAL TO OUR COLLECTION, USE AND DISCLOSURE
              OF SUCH INDIVIDUAL'S PERSONAL INFORMATION AS DESCRIBED IN THIS PRIVACY POLICY.
              YOU FURTHER UNDERSTAND THAT THIS WEBSITE MAY CONTAIN LINKS TO OTHER
              SITES THAT WE DO NOT OPERATE. IF YOU CLICK ON A THIRD-PARTY LINK, YOU
              WILL BE DIRECTED TO THAT THIRD PARTY'S SITE. WE ADVISE YOU TO REVIEW
              THE PRIVACY POLICY OF EVERY SITE YOU VISIT. WE HAVE NO CONTROL OVER AND
              ASSUME NO RESPONSIBILITY FOR THE CONTENT, PRIVACY POLICIES, OR PRACTICES
              OF ANY THIRD-PARTY SITES OR SERVICES.
            </p>
          </div>
          <div className="mb-5">
            <p className="text-white text-bold mb-4">Children's Policy</p>
            <p className="text-white text fw-normal">
              Use of our Website is available only to persons who can form a
              legally binding contract under the applicable law. We do not
              knowingly solicit or collect personal information from children
              under the age of 18 years. If you have shared any personal
              information of children under the age of 18 years, you represent
              that you have the authority to do so and permit us to use the
              information in accordance with this Privacy Policy.
            </p>
          </div>
          <div className="mb-5">
            <p className="text-white text-bold mb-4">Data Retention</p>
            <p className="text-white text fw-normal">
              We retain your personal information for a period no longer than
              is required for the purpose for which it was collected or as
              required under any applicable law. However, we may retain data
              related to you if there is a legal obligation to retain the data;
              if required to comply with any applicable statutory or regulatory
              retention requirement by law; if we believe it may be necessary
              to prevent fraud or future abuse; to enable Master The Sky - Drones
              Unleashed to exercise its legal rights and/or defend against legal
              claims; or for other legitimate purposes. We may continue to retain
              your data in anonymized form for analytical and research purposes.
            </p>
          </div>
          <div className="mb-5">
            <p className="text-white text-bold mb-4">Consent</p>
            <p className="text-white text fw-normal">
              By visiting our website or by providing your information, you
              consent to the collection, use, storage, transfer, disclosure and
              otherwise processing of your information (including sensitive personal
              information) on the Website in accordance with this Privacy Policy.
              If you disclose to us any personal information relating to other
              people, you represent that you have the authority to do so and permit
              us to use the information in accordance with this Privacy Policy. You,
              while providing your personal information over the Website or any
              partner websites or establishments, consent to us (including our other
              corporate entities, affiliates, lending partners, technology partners,
              marketing channels, business partners, and other third parties) to
              contact you through SMS, instant messaging apps, call and/or e-mail
              for the purposes specified in this Privacy Policy. You have an option
              to withdraw your consent that you have already provided by writing
              to the Grievance Officer at the contact information provided below.
              Please mention “Withdrawal of consent for processing personal information”
              in the subject line of your communication. We will verify such requests
              before acting on our request. Please note, however, that withdrawal of
              consent will not be retroactive and will be in accordance with the terms
              of this Privacy Policy, related terms of use, and applicable laws. In
              the event you withdraw the consent given to us under this Privacy Policy,
              we reserve the right to restrict or deny the provision of our services
              for which we consider such information to be necessary.
            </p>
          </div>
          <div className="mb-5">
            <p className="text-white text-bold mb-4">Charges to this Policy</p>
            <p className="text-white text fw-normal">
              We may update this Policy from time to time. If the changes materially
              affect the way we use identifiable information that we have already
              collected, we shall notify you on our Website. The continued usage of
              our website after such changes have been made to these terms will mean
              that you accept these changes. Therefore, you are requested to
              kindly check the Policy regularly for updates.
            </p>
          </div>
          <div className="mb-5">
            <p className="text-white text-bold mb-4">GRIEVANCE OFFICER</p>
            <p className="text-white text fw-normal">
              In accordance with the Information Technology Act 2000 and rules made
              there under the Consumer Protection (E-Commerce) Rules, 2020, the name
              and contact details of our Grievance Officer are provided below:
              <br />
              <br />
              Name: Reema Maheshwari
              <br />
              Designation: Co-Founder
              <br />
              Address: Wakad, Pune, Maharashtra- 411057
              <br />
              E-mail ID: talk2us@pastelcube.com
            </p>
          </div>
        </div>
        <Footer />
      </section>
    </BackgroundWrapper>
  );
};

export default UserPrivacyPolicy;
