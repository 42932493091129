import React from "react";
import { qr_code } from "../../assets";
import { useMediaQuery } from "react-responsive";

export const QrCodeView = ({
  showLabel = true,
  className,
  style,
  imageStyle,
}) => {
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });

  return (
    <div className={className} style={style}>
      <div className="d-flex flex-column justify-content-center align-items-center">
        {showLabel && (
          <p className="text-white text-center qr-code-text mb-2">
            Scan to Download
          </p>
        )}
        <img
          src={qr_code}
          alt="QR code"
          className="img-fluid"
          style={imageStyle}
        />
      </div>
    </div>
  );
};

export default QrCodeView;
