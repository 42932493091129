import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import BackgroundWrapper from "../../components/BackgroundWrapper";
import {
  drone_bg_dark,
  drone_bg_dark_mobile,
  drone_bg_extra_dark,
} from "../../assets";
import SiteNavbar from "../../components/Navbar/Navbar";
import NewsSection from "../../sections/News/NewsSection";
import Footer from "../../components/Footer/Footer";

const News = ({ overflow, showNavbar = true, className }) => {
  const navigate = useNavigate();
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });

  const [show, setShow] = useState(false);

  return (
    <div>
      <BackgroundWrapper
        backgroundImage={large ? drone_bg_extra_dark : drone_bg_dark_mobile}
        className={`${className}`}
      >
        <section
          className="d-flex flex-column content-wrapper h-100 position-relative scrollbar-none"
          style={{ overflowY: "scroll" }}
        >
          {showNavbar && (
            <SiteNavbar
              showBackIcon={show}
              backAction={() => setShow(false)}
              showLogo={false}
              title="News"
            />
          )}
          <div className="d-flex flex-column flex-grow-1">
            {large && (
              <h1 className="text-center text-white text-uppercase mt-2 mb-2">
                News
              </h1>
            )}
            <NewsSection show={show} setShow={setShow} />
          </div>
        </section>
      </BackgroundWrapper>
      <Footer />
    </div>
  );
};

export default News;
