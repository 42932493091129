import React from "react";
import BackgroundWrapper from "../../components/BackgroundWrapper";
import { about_bg, drone_bg_dark_mobile, drone_right } from "../../assets";
import { useMediaQuery } from "react-responsive";
import SiteNavbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { Col, Row } from "react-bootstrap";

const data =
  "Immerse yourself in the thrilling world of drone racing with 'Master The Sky - Drones Unleashed,' brought to you by Pastelcube Technologies. Get ready to spread your wings and soar through breathtaking landscapes, piloting top-tier drones in heart- pounding adventures. Key features include adrenaline-fueled drone racing, diverse and immersive settings, a streamlined user interface for seamless gaming, stunning visuals, immersive soundscapes, and challenging drone flight mastery. Are you prepared to conquer the skies and become a true drone virtuoso? Join our community, and let's rewrite the skies together!";

const AboutUs = () => {
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });

  return (
    <div>
      <BackgroundWrapper
        backgroundImage={large ? about_bg : drone_bg_dark_mobile}
        className="d-flex flex-column align-items-center"
      >
        <section
          className="w-100 content-wrapper scrollbar-none"
          style={{ overflowY: "scroll", flexGrow: 1 }}
        >
          <SiteNavbar showLogo={false} title="About Us" />
          <Row xs={1} lg={2} className="align-items-center">
            <Col lg={{ order: 2 }} className="p-0">
              <div className="d-flex justify-content-end w-100">
                <img
                  src={drone_right}
                  alt="drone"
                  className="img-fluid"
                  style={{
                    objectFit: "contain",
                    width: xlarge
                      ? "85%"
                      : large
                        ? "100%"
                        : medium
                          ? "80%"
                          : small
                            ? "80%"
                            : "100%",
                    marginRight: xlarge ? "-8%" : large ? "-8%" : "-4%",
                    marginTop: xlarge ? "8%" : large ? "8%" : "4%",
                  }}
                />
              </div>
            </Col>
            <Col lg={{ order: 1 }} className="p-0">
              <div
                className="d-flex flex-column mx-auto mx-lg-0 px-4 content-wrapper mb-4"
                style={{
                  width: large ? "100%" : medium ? "95%" : "100%",
                  marginTop: large ? "15%" : "5%",
                }}
              >
                <h1 className="text-center text-lg-start text-white text-uppercase mb-4 mb-lg-5">
                  About Us
                </h1>
                <p className="text-center text-lg-start text text-white">
                  Immerse yourself in the thrilling world of drone racing with
                  &nbsp;<a href="/">'Master The Sky - Drones Unleashed'</a>
                  , brought to you by Pastelcube Technologies. Get ready to spread
                  your wings and soar through breathtaking landscapes, piloting
                  top-tier drones in heart- pounding adventures. Key features include
                  adrenaline-fueled drone racing, diverse and immersive settings,
                  a streamlined user interface for seamless gaming, stunning visuals,
                  immersive soundscapes, and challenging drone flight mastery. Are you
                  prepared to conquer the skies and become a true drone virtuoso?
                  Join our community, and let's rewrite the skies together!
                </p>
              </div>
            </Col>
          </Row>
        </section>
      </BackgroundWrapper>
      <Footer />
    </div>
  );
};

export default AboutUs;
