import React from "react";
import { Button } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";

const ActionButton = ({
  title,
  icon,
  child,
  type,
  href,
  onClick,
  className,
  fontClassName,
  style,
}) => {
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });
  const xxlarge = useMediaQuery({ query: "(min-width: 1400px)" });

  return (
    <Button
      type={type}
      href={href}
      onClick={onClick}
      target="_blank"
      className={`d-flex justify-content-start align-items-center bg-secondary text-lowercase border-secondary rounded-0 px-3 px-sm-3 px-xl-3 py-0 w-100 ${className}`}
      style={{
        height: xxlarge
          ? 50
          : xlarge
          ? 35
          : large
          ? 30
          : medium
          ? 36
          : small
          ? 36
          : 36,
        ...style,
      }}
    >
      <div className="d-flex justify-content-center align-items-center w-100">
        {icon && (
          <div className="" style={styles.icon}>
            {icon}
          </div>
        )}
        {title ? (
          <p
            className={`action-button-text text-white text-center w-100 ${
              icon && "ms-2"
            } ${fontClassName} text-lowercase`}
            style={styles.child}
          >
            {title}
          </p>
        ) : (
          child && (
            <div
              className={`text-white text-center w-100 ${
                icon ? "ms-1 ms-sm-2" : ""
              }`}
              style={styles.child}
            >
              {child}
            </div>
          )
        )}
      </div>
    </Button>
  );
};

const styles = {
  icon: { minWidth: 22, width: 30 },
  child: {
    minWidth: 60,
  },
};

export default ActionButton;
