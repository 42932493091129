import React from "react";
import { Col, Row } from "react-bootstrap";
import ActionButton from "../../components/Buttons/ActionButton";
import { google_play, google_play_text, oculus, steam } from "../../assets";
import { useMediaQuery } from "react-responsive";

export const actionButtonLinks = {
  oculusRiftS: {
    title: "oculus rift",
    link: "https://www.meta.com/experiences/pcvr/6736998746351006/?ranking_trace=0_6736998746351006_SEARCH_e92e9fe1-2543-4613-8c63-cc0243ab6b61",
  },
  googlePlay: {
    title: "Google Play",
    link: "https://play.google.com/store/apps/details?id=com.PastelcubeTechnologiesPvtLtd.MasterTheSky",
  },
  steam: {
    title: "STEAM™",
    link: "https://store.steampowered.com/app/2510880/Master_The_Sky__Drones_Unleashed/",
  },
  oculus: {
    title: "oculus",
    link: "https://www.meta.com/experiences/6369043279819217/?ranking_trace=0_6369043279819217_QUESTSEARCH_3a225395-27f0-4ac7-9a24-d4a76febc5db",
  },
};

const ActionButtons = ({
  colsXs,
  colsLg,
  colClass,
  btnClass,
  className,
  style,
}) => {
  const msmall = useMediaQuery({ query: "(min-width: 340px)" });
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });
  const xxlarge = useMediaQuery({ query: "(min-width: 1400px)" });
  return (
    <Row xs={colsXs ?? 1} lg={colsLg} className={className} style={style}>
      <Col className={`d-flex justify-content-end pe-0 pb-2 ${colClass}`}>
        <ActionButton
          href={actionButtonLinks.oculusRiftS.link}
          title={actionButtonLinks.oculusRiftS.title}
          icon={<img src={oculus} alt="Oculus" className="img-fluid w-100" />}
          fontClassName="action-button-text action-button-text-cabin"
          className={btnClass}
        />
      </Col>
      <Col className={`d-flex justify-content-end pe-0 pb-2 ${colClass}`}>
        <ActionButton
          href={actionButtonLinks.googlePlay.link}
          icon={
            <img
              src={google_play}
              alt="Google Play"
              className="img-fluid w-100 me-1"
            />
          }
          fontClassName="text-lowercase"
          className={btnClass}
          child={
            <img
              src={google_play_text}
              alt="Google Play"
              className="img-fluid"
              style={{
                width: medium ? "80%" : small ? "90" : "96%",
                height: 50,
                maxWidth: 100,
              }}
            />
          }
        />
      </Col>
      <Col className={`d-flex justify-content-end pe-0 pb-2 ${colClass}`}>
        <ActionButton
          href={actionButtonLinks.steam.link}
          title={actionButtonLinks.steam.title}
          icon={<img src={steam} alt="Steam" className="img-fluid w-100" />}
          fontClassName="action-button-text"
          className={btnClass}
        />
      </Col>
      <Col className={`d-flex justify-content-end pe-0 pb-2 ${colClass}`}>
        <ActionButton
          href={actionButtonLinks.oculus.link}
          title={actionButtonLinks.oculus.title}
          icon={<img src={oculus} alt="Oculus" className="img-fluid w-100" />}
          fontClassName="action-button-text action-button-text-cabin"
          className={btnClass}
        />
      </Col>
    </Row>
  );
};

export default ActionButtons;
