import React, { useEffect, useRef, useState } from "react";
import { Card, CardGroup, Col, Modal, Row } from "react-bootstrap";
import { SharpLine } from "../../components/Site/SharpLine";
import QrCodeView from "../../components/Site/QrCodeView";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import {
  back_icon_circle,
  double_chevron_left,
  double_chevron_right,
  drone_bg,
  drone_bg_dark,
} from "../../assets";
import BackgroundWrapper from "../../components/BackgroundWrapper";
import * as userActions from "../../Utilities/UserActions";
import Loader from "../../components/Site/Loader";
import NewsCarouselCard from "../../components/Updates/NewsCarouselCard";
import Slider from "react-slick";

export function formatDate(inputDate) {
  const parts = inputDate.split("-"); // Split the input string by hyphens
  if (parts.length === 3) {
    // Check if the input has three parts (year, month, and day)
    const year = parts[0].substring(2); // Get the last two digits of the year
    const month = parts[1];
    const day = parts[2];
    return `${month}/${year}//`;
  } else {
    return "Invalid date format";
  }
}

const NewsSection = ({ show, setShow, showNavbar }) => {
  const navigate = useNavigate();
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });
  const xxlarge = useMediaQuery({ query: "(min-width: 1400px)" });

  // const [show, setShow] = useState(false);
  const [newsData, setNewsData] = useState([]);
  const [newsCarouselData, setNewsCarouselData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const totalCarouselItems = 3;
  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  const sliderRef = useRef(null);

  const goToSlide = (index) => {
    sliderRef.current.slickGoTo(index);
  };

  const getNewsForNewsSection = async () => {
    const res = await userActions.fetchNewsForNewsSection();
    if (res) {
      console.log(res);
      setNewsData(res);
      const filteredRes = res.filter((item) => !item.is_special_event);
      setNewsCarouselData(filteredRes);
    } else {
      console.log("Error in loading latest updates");
    }
  };

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  const onClickPrev = () => {
    if (currentIndex !== 0) {
      setCurrentIndex(currentIndex - 1);
      goToSlide(currentIndex - 1);
    } else {
      setCurrentIndex(totalCarouselItems - 1);
      goToSlide(totalCarouselItems - 1);
    }
  };

  const onClickNext = () => {
    if (currentIndex !== totalCarouselItems - 1) {
      setCurrentIndex(currentIndex + 1);
      goToSlide(currentIndex + 1);
    } else {
      setCurrentIndex(0);
      goToSlide(0);
    }
  };

  useEffect(() => {
    getNewsForNewsSection();
  }, []);

  const styles = {
    newsImageStyle: {
      objectFit: "cover",
      height: xlarge
        ? "40vh"
        : large
          ? "30vh"
          : medium
            ? "56vh"
            : small
              ? "40vh"
              : "35vh",
      // height: xlarge ? 260  : large  ? 200  : medium  ? 400  : small  ? 280  : 220,
    },
    carouselSharpLinesStyle: {
      width: xlarge ? 340 : medium ? 300 : small ? 200 : 120,
    },
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: large,
    initialSlide: currentIndex,
    afterChange: (index) => {
      setCurrentIndex(index);
    },
    prevArrow: (
      <img
        src={double_chevron_left}
        alt="Prev"
        className="img-fluid"
        onClick={onClickPrev}
      />
    ),
    nextArrow: (
      <img
        src={double_chevron_right}
        alt="Next"
        className="img-fluid"
        onClick={onClickNext}
      />
    ),
  };

  return (
    <CardGroup className="flex-grow-1">
      {!show && (
        <Row
          xs={1}
          lg={3}
          className="pt-lg-4 pt-xl-5 scrollbar-none flex-grow-1 scrollbar-none"
          style={{ overflowY: "scroll" }}
        >
          {newsData.length > 0 ? (
            newsData?.map((item, index) => {
              return (
                index < 3 &&
                !item.is_special_event && (
                  <Col
                    key={index}
                    className="pb-2 pb-lg-0 p-0 p-lg-2 p-xl-3"
                  // onClick={() => {
                  //   setCurrentIndex(index);
                  //   setShow(true);
                  //   setCurrentIndex(index);
                  // }}
                  >
                    <Card className="bg-transparent border-0 rounded-0 px-3 px-md-2 px-xl-3 mb-4 cursor-pointer">
                      <h4 className="text-white mb-3 mb-md-4 mb-lg-5 fw-normal">
                        <b>{formatDate(item?.published_date)}</b>
                        {/* {new Date(item?.published_date).toLocaleDateString(
                          "en-GB"
                        )} */}
                      </h4>
                      <img
                        src={item.news_image}
                        alt={item.alt_tag ?? "News"}
                        className="img-fluid w-100 mb-3 mb-md-4 mb-lg-5 cursor-pointer"
                        style={styles.newsImageStyle}
                        onClick={() => {
                          setCurrentIndex(index);
                          setShow(true);
                          setCurrentIndex(index);
                        }}
                      />
                      {index === 0 && (
                        <h2 className="text-center text-white text-uppercase max-lines-2 mt-lg-2 newsTitle">
                          {item.title}
                        </h2>
                      )}
                      {index === 1 && (
                        <h3 className="text-center text-white text-uppercase max-lines-2 mt-lg-2 newsTitle">
                          {item.title}
                        </h3>
                      )}
                      {index === 2 && (
                        <h4 className="text-center text-white text-uppercase max-lines-2 mt-lg-2 newsTitle">
                          {item.title}
                        </h4>
                      )}
                    </Card>
                  </Col>
                )
              );
            })
          ) : (
            <Loader />
          )}
        </Row>
      )}
      <Modal
        show={show}
        onHide={() => setShow(false)}
        animation={false}
        fullscreen
        dialogClassName="custom-modal"
        style={{ zIndex: 4999 }}
      >
        <BackgroundWrapper
          backgroundImage={drone_bg_dark}
          className="d-flex flex-column"
          style={{ height: "100vh" }}
        >
          <div
            closeButton
            className={`border-0 ms-2 ms-md-3 ms-lg-4 me-3 mt-3 mt-md-3 mt-lg-3 position-relative ${
              // className={`border-0 ms-2 ms-md-3 ms-lg-4 me-3 mt-lg-4 position-relative ${
              xxlarge ? "pt-lg-4" : xlarge ? "pt-lg-4" : "pt-lg-4"
              }`}
          >
            <div
              className="me-3 pt-2 mt-md-1 pt-lg-0 mt-lg-0 position-absolute cursor-pointer"
              style={{ minWidth: 28, width: 28 }}
              onClick={() => setShow(false)}
            >
              <img
                src={back_icon_circle}
                alt="back"
                className="img-fluid w-100"
              />
            </div>
            {!large && (
              <div className="d-flex d-lg-none align-items-center pt-2 ms-5">
                <h2 className="text-white me-4">News</h2>
                <SharpLine
                  toRight={true}
                  style={{ width: medium ? 300 : small ? 250 : 120 }}
                />
              </div>
            )}
            {large && (
              <div className="w-100 mt-lg-2">
                <h2 className="text-center text-white text-uppercase mt-3">
                  News
                </h2>
              </div>
            )}
          </div>
          <Modal.Body className="d-flex flex-column p-0 pt-lg-0 scrollbar-none">
            {/* {large && (
              <h2 className="text-center text-white text-uppercase mb-4 mb-5">
                News
              </h2>
            )} */}
            <div className="d-flex flex-column flex-grow-1 content-wrapper mx-2 mx-sm-4 mt-lg-5">
              {/* {newsData.length > 0 ? (
                newsData
                  ?.filter((item, index) => index === currentIndex)
                  .map((item, i) => (
                    <NewsCarouselCard
                      key={i}
                      title={item.title}
                      image={item.news_image}
                      content={item.content}
                      onClickPrev={onClickPrev}
                      onClickNext={onClickNext}
                    />
                  ))
              ) : (
                <Loader />
              )} */}
              {newsCarouselData.length > 0 ? (
                <Slider
                  ref={sliderRef}
                  {...settings}
                  className="d-flex flex-grow-1"
                >
                  {newsCarouselData.map((item, i) => (
                    <NewsCarouselCard
                      key={i}
                      title={item.title}
                      image={item.news_image}
                      content={item.content}
                      onClickPrev={onClickPrev}
                      onClickNext={onClickNext}
                    />
                  ))}
                </Slider>
              ) : (
                <Loader />
              )}
            </div>
            <div
              className="d-flex overflow-hidden"
              style={{
                width: screenSize.width,
                minHeight: xxlarge ? 118 : xlarge ? 112 : large ? 100 : 60,
              }}
            >
              <div
                className="d-flex justify-content-center align-items-center w-100"
                style={{ minHeight: large ? 100 : 68 }}
              >
                <SharpLine
                  // toLeft={true}
                  style={styles.carouselSharpLinesStyle}
                />
                <div className="d-flex justify-content-center align-items-center mx-2 mx-md-3 mx-lg-4">
                  {newsData &&
                    Array.from({ length: 3 }, (_, index) => (
                      <div
                        className={`mx-2 cursor-pointer ${index === currentIndex ? "bg-orange" : "bg-grey"
                          }`}
                        style={{ width: 10, height: 10 }}
                        onClick={() => {
                          setCurrentIndex(index);
                          goToSlide(index);
                        }}
                      />
                    ))}
                </div>
                <SharpLine
                  // toRight={true}
                  style={styles.carouselSharpLinesStyle}
                />
              </div>
              <QrCodeView
                className="d-none d-lg-block position-absolute"
                imageStyle={{ width: 75, height: 72 }}
                style={{ right: 30, bottom: 20 }}
              />
              {/* </div> */}
            </div>
          </Modal.Body>
        </BackgroundWrapper>
      </Modal>
    </CardGroup>
  );
};

export default NewsSection;
