import React from "react";
import { double_chevron_left, double_chevron_right } from "../../assets";
import { useMediaQuery } from "react-responsive";
import { SharpLine } from "../Site/SharpLine";

const NewsCarouselCard = ({
  key,
  active,
  title,
  image,
  content,
  onClickPrev,
  onClickNext,
}) => {
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });
  const xxlarge = useMediaQuery({ query: "(min-width: 1400px)" });
  const xxxlarge = useMediaQuery({ query: "(min-width: 1600px)" });

  const styles = {
    image: {
      height: xlarge
        ? "45vh"
        : large
        ? "50vh"
        : medium
        ? "50vh"
        : small
        ? "40vh"
        : "36vh",
    },
  };

  return (
    <div
      key={key}
      className="d-flex flex-column bg-transparent cursor-pointer flex-grow-1"
      style={{ minHeight: xxxlarge ? "74vh" : "66vh" }}
    >
      {large && (
        <div
          className="d-flex justify-content-center align-items-center mx-auto mb-4 mb-lg-4"
          style={{ minHeight: 60, width: "96%" }}
        >
          <h4 className="text-center text-white text-uppercase max-lines-2">
            {title}
          </h4>
        </div>
      )}
      <div className="d-flex align-items-lg-center pt-5 pt-lg-0 w-100 flex-grow-1">
        <div
          className="d-flex flex-column flex-lg-row w-100 align-items-center px-2 px-sm-3 px-md-4 scrollbar-none"
          // style={{ height: large ? "40vh" : "75vh", overflowY: "scroll" }}
        >
          <div
            className="d-flex justify-content-center justify-content-lg-end align-items-center w-100 mt-sm-2 mt-md-3 mt-lg-0 mb-2 mb-md-3 mb-lg-0"
            style={{ height: styles.image.height }}
          >
            <img
              src={image}
              alt="news_image"
              className="img-fluid text-white"
              style={{
                objectFit: "cover",
                height: styles.image.height,
                width: large ? "80%" : medium ? "90%" : "100%",
                // height: xlarge ? 260 : large ? 200 : medium ? 400 : small ? 280 : 220,
              }}
            />
          </div>
          {!large && (
            <div className="my-5 mb-lg-4">
              <h4 className="text-center text-white text-uppercase">{title}</h4>
            </div>
          )}
          <div className="d-none d-lg-flex justify-content-around align-items-center w-25">
            <SharpLine
              isVertical={true}
              style={{ height: xxlarge ? 260 : 200 }}
            />
          </div>
          <div
            className="w-100 scrollbar-none flex-grow-1"
            style={{ overflowY: large ? "scroll" : "default" }}
          >
            <div
              className="text-white text me-lg-5"
              dangerouslySetInnerHTML={{ __html: content }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsCarouselCard;
