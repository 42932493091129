import React from "react";
import { SharpLine } from "../Site/SharpLine";
import { Col, Row } from "react-bootstrap";
import QrCodeView from "../Site/QrCodeView";
import { drone_logo, heart } from "../../assets";
import SocialIconsView from "./SocialIconsView";
import { useMediaQuery } from "react-responsive";
import ActionButtons from "../../sections/Home/ActionButtons";
import { HiLink } from "react-icons/hi";
import { Link } from "react-router-dom";

const Footer = () => {
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });
  const xxlarge = useMediaQuery({ query: "(min-width: 1400px)" });

  return (
    <section className="bg-black p-4">
      <Row className="d-flex justify-content-center align-items-center px-md-4">
        <Col
          xs={{ span: 4, order: 1 }}
          sm={{ span: 2, order: 1 }}
          lg={{ span: 1, order: 1 }}
          className="mb-3"
        >
          <img src={drone_logo} alt="drone" className="img-fluid" />
        </Col>
        <Col
          xs={{ span: 12, order: 3 }}
          sm={{ span: 8, order: 2 }}
          lg={{ span: 10, order: 2 }}
          className="mb-3"
        >
          <SocialIconsView
            className={`m-3 mx-lg-4 mb-lg-4 ${xxlarge ? "mb-xl-5" : "pb-2"}`}
          // iconClass={`m-3 mx-lg-4 mb-lg-4 ${xxlarge ? "mb-xl-5" : ""}`}
          />
          <div className="d-flex justify-content-center w-100">
            <ActionButtons
              colsXs={2}
              colsLg={4}
              colClass={xxlarge ? "px-lg-3" : "px-lg-3"}
              btnClass="w-100"
              style={{ width: xxlarge ? "62%" : large ? "62%" : "100%" }}
            />
          </div>
        </Col>
        <Col
          xs={{ span: 4, order: 2 }}
          sm={{ span: 2, order: 3 }}
          lg={{ span: 1, order: 3 }}
          className="mb-3"
        >
          <QrCodeView showLabel={false} imageStyle={{ maxWidth: 72 }} />
        </Col>
      </Row>
      <div
        className={`w-100 text-center py-2 ${xxlarge ? "my-4" : "mt-2 mb-4"}`}
      >
        <p className="text fw-normal">
          <span className="text-white">
            Kindly refer to this Privacy Policy for more details -{" "}
          </span>
          <Link to="/user-privacy-policy">
            <u className="me-2" style={{ color: "0E80E6" }}>
              Users Privacy Policy
            </u>
            <HiLink size={20} color="0E80E6" />
          </Link>
        </p>
      </div>
      <SharpLine className="w-75 mx-auto my-4" />
      <Row xs={1} lg={3}>
        <Col className="text-center">
          <p className="text-white text fw-normal mb-3">
            CIN : U72900N2020PTC193934
          </p>
        </Col>
        <Col className="text-center">
          <a
            className="text-white text fw-normal mb-3 text-capitalize"
            href="https://www.pastelcube.com/"
            target="_blank"
          >
            Pastelcube Technologies Private Limited
          </a>
        </Col>
        <Col className="d-flex justify-content-center align-items-center">
          <div className="d-flex justify-content-center align-items-center mb-3">
            <p className="text-white text fw-normal me-2">Made In India with</p>
            <img src={heart} alt="love" className="img-fluid" />
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default Footer;
