import React from "react";
import { Spinner } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";

const Loader = () => {
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  return (
    <div
      className="d-flex justify-content-center align-items-center w-100"
      style={{ height: "60vh" }}
    >
      <Spinner animation="border" className="text-white" />
    </div>
  );
};

export default Loader;
