import React, { useEffect, useState } from "react";
import { double_chevron_left, double_chevron_right } from "../../assets";
import { useMediaQuery } from "react-responsive";
import { SharpLine } from "../Site/SharpLine";

const TimelineCarouselCard = ({
  active,
  title,
  image,
  altTag,
  content,
  onClickPrev,
  onClickNext,
}) => {
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });

  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  const styles = {
    imageStyles: {
      width: large
        ? screenSize.width * 0.28
        : medium
        ? screenSize.width * 0.5
        : small
        ? screenSize.width * 0.65
        : screenSize.width * 0.8,
    },
  };

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  return (
    <div className="bg-transparent py-2 py-lg-4">
      {large && (
        <div
          className="mb-4 mb-lg-5 mx-auto"
          style={{ minHeight: 60, width: "95%" }}
        >
          <h3
            className="text-center text-white text-uppercase max-lines-2"
            style={{ height: 50 }}
          >
            {title}
          </h3>
          <SharpLine className="mx-auto" style={{ width: 400 }} />
        </div>
      )}
      <div className="d-flex align-items-center w-100">
        {/* <div className="d-none d-lg-inline-block" onClick={onClickPrev}>
          <img
            src={double_chevron_left}
            alt=""
            className="img-fluid cursor-pointer"
          />
        </div> */}
        <div
          className="d-flex flex-column w-100 align-items-center px-lg-4 scrollbar-none"
          style={{ minHeight: large ? "40vh" : "75vh", overflowY: "scroll" }}
        >
          <div
            className="d-flex justify-content-center align-items-center mb-4 w-100"
            // style={{
            //   width: styles.imageStyles.width,
            //   height: styles.imageStyles.width,
            // }}
          >
            {/* <div className="d-none d-lg-inline-block" onClick={onClickPrev}>
              <img
                src={double_chevron_left}
                alt=""
                className="img-fluid cursor-pointer"
              />
            </div> */}
            <div className="d-flex justify-content-center w-100 text-center">
              <img
                src={image}
                alt={altTag ?? "News image"}
                className="img-fluid"
                style={{
                  objectFit: "cover",
                  width: styles.imageStyles.width,
                  height: styles.imageStyles.width,
                }}
              />
            </div>
            {/* <div className="d-none d-lg-inline-block" onClick={onClickNext}>
              <img
                src={double_chevron_right}
                alt=""
                className="img-fluid cursor-pointer"
              />
            </div> */}
          </div>
          {!large && (
            <div
              className="mb-4 mb-lg-5"
              style={{ minHeight: large ? 60 : 16, width: "95%" }}
            >
              <h4 className="text-center text-white text-uppercase max-lines-4">
                {title}
              </h4>
            </div>
          )}
          <div
            className="text-white text-center text w-100 px-2 px-sm-3 px-md-4 px-lg-5 pt-md-3"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
        {/* <div className="d-none d-lg-inline-block" onClick={onClickNext}>
          <img
            src={double_chevron_right}
            alt=""
            className="img-fluid cursor-pointer"
          />
        </div> */}
      </div>
    </div>
  );
};

export default TimelineCarouselCard;
