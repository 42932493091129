import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";

const WallpaperCard = ({ key, item }) => {
  const xsmall = useMediaQuery({ query: "(max-width: 380px)" });
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });
  const [show, setShow] = useState(false);

  const downloadFile = (link) => {
    console.log(link);
    fetch(link, {
      method: "GET",
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "wallpaper.png");
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const styles = {
    downloadButtonsLink: {
      width: large ? 320 : medium ? 320 : small ? 320 : xsmall ? 260 : 300,
    },
    downloadButtons: {
      height: medium ? 50 : small ? 50 : xsmall ? 36 : 44,
      borderWidth: 2,
    },
  };

  return (
    // <div
    //   className="mb-5 overflow-hidden position-relative"
    //   style={{
    //     background: `url(${item.image})`,
    //     backgroundSize: "cover",
    //     backgroundRepeat: "no-repeat",
    //     backgroundPosition: "center",
    //     height: large ? "50vh" : medium ? "40vh" : small ? "40vh" : "40vh",
    //   }}
    // >
    // </div>
    <div
      key={key}
      className="mx-auto mb-5 position-relative"
      style={{
        overflow: "hidden",
        width: large ? "75%" : medium ? "80%" : small ? "90%" : "90%",
        // background: "linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))",
      }}
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      <img
        src={large ? item.desktop_image : item.mobile_image}
        alt={large ? item?.alt_tag : item?.alt_tag}
        className="img-fluid w-100"
        style={{
          objectFit: "cover",
          // height: large ? "50vh" : medium ? "40vh" : small ? "40vh" : "36vh",
          // background: "linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))",
        }}
      />
      {show && (
        <div className="position-absolute w-100" style={{ bottom: 10 }}>
          <div className="d-flex flex-column flex-md-row justify-content-md-center align-items-center">
            {large && (
              // <a href={item?.mobile_wallpaper} download={`${item?.mobile_wallpaper}`}>
              <div className="me-5" style={styles.downloadButtonsLink}>
                {/* <SiteButton
                  title="Download for PC"
                  className="bg-transparent border-white px-0"
                  style={styles.downloadButtons}
                  onClick={() => downloadFile(item?.desktop_image)}
                /> */}
                <Button
                  className="bg-transparent wallpapers-border-gradient-button w-100 mb-2 rounded-0 text-bold px-0"
                  style={styles.downloadButtons}
                  onClick={() => downloadFile(item?.desktop_image)}
                >
                  <b>Download for PC</b>
                </Button>
              </div>
            )}
            <div className="ms-lg-4" style={styles.downloadButtonsLink}>
              {/* <SiteButton
                title={large ? "Download for Mobile" : "Download"}
                className="bg-transparent border-white px-0"
                style={styles.downloadButtons}
                onClick={() => downloadFile(item?.mobile_image)}
              /> */}
              <Button
                className="bg-transparent wallpapers-border-gradient-button w-100 mb-2 rounded-0 text-bold px-0"
                style={styles.downloadButtons}
                onClick={() => downloadFile(item?.mobile_image)}
              >
                <b>Download for Mobile</b>
              </Button>
            </div>
          </div>
        </div>
      )}
      {!large && <div className="position-absolute w-100" style={{ bottom: 10 }}>
        <div className="d-flex flex-column flex-md-row justify-content-md-center align-items-center">
          <div className="ms-lg-4" style={styles.downloadButtonsLink}>
            {/* <SiteButton
                title={large ? "Download for Mobile" : "Download"}
                className="bg-transparent border-white px-0"
                style={styles.downloadButtons}
                onClick={() => downloadFile(item?.mobile_image)}
              /> */}
            <Button
              className="bg-transparent wallpapers-border-gradient-button w-100 mb-2 rounded-0 text-bold px-0"
              style={styles.downloadButtons}
              onClick={() => downloadFile(item?.mobile_image)}
            >
              <b>Download</b>
            </Button>
          </div>
        </div>
      </div>}
    </div>
  );
};

export default WallpaperCard;
