import axios from "axios";
import { toast } from "react-toastify";

// const toastConf = {
//   position: "top-right",
//   autoClose: 3000,
//   closeOnClick: true,
//   pauseOnHover: true,
//   draggable: true,
//   progress: undefined,
//   pauseOnFocusLoss: false,
//   hideProgressBar: true,
//   className: "my-toast",
//   theme: "dark",
//   transition: Flip,
// };

export const notify = (msg, type) => {
  switch (type) {
    case "success":
      toast.success(msg);
      break;
    case "error":
      toast.error(msg);
      break;
    case "warning":
      toast.warning(msg);
      break;
    case "info":
      toast.info(msg);
      break;
    default:
      toast.error(msg);
  }
};

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

////////////// ---- News ---- /////////////

export const fetchNews = async () => {
  try {
    const response = await axios.get(`/news/news/`);
    if (response.status === 200) {
      console.log("fetchNews Response_____", response);
      return response.data;
    }
  } catch (err) {
    console.log("fetchNews Error", err);
    // notify("Error in fetchNews", "error");
    return err;
  }
};

export const fetchNewsById = async (id) => {
  try {
    const response = await axios.get(`/news/news/${id}/`);
    if (response.status === 200) {
      console.log("fetchNewsById Response_____", response);
      return response.data;
    }
  } catch (err) {
    console.log("fetchNewsById Error", err);
    // notify("Error in fetchNewsById", "error");
    return err;
  }
};

// export const fetchLatestNews = async (date) => {
//   try {
//     const response = await axios.get(`/news/news/?is_trending=${true}`);
//     if (response.status === 200) {
//       console.log("fetchLatestNews Response_____", response);
//       return response.data;
//     }
//   } catch (err) {
//     console.log("fetchLatestNews Error", err);
//     // notify("Error in fetchLatestNews", "error");
//     return err;
//   }
// };

export const fetchNewsForNewsSection = async (date) => {
  try {
    const response = await axios.get(`/news/news/?show_in_news=${true}`);
    if (response.status === 200) {
      console.log("fetchNewsForNewsSection Response_____", response);
      return response.data;
    }
  } catch (err) {
    console.log("fetchNewsForNewsSection Error", err);
    // notify("Error in fetchNewsForNewsSection", "error");
    return err;
  }
};

export const fetchNewsForTimeline = async (date) => {
  try {
    const response = await axios.get(`/news/news/?show_in_timeline=${true}`);
    if (response.status === 200) {
      console.log("fetchNewsForTimeline Response_____", response);
      return response.data;
    }
  } catch (err) {
    console.log("fetchNewsForTimeline Error", err);
    // notify("Error in fetchNewsForTimeline", "error");
    return err;
  }
};

// export const addNews = async (data) => {
//   try {
//     console.log(data);
//     const response = await axios.post(`/news/news/`, data);
//     if (response.status === 201) {
//       console.log("addNews Response_____", response);
//       return response.data;
//     }
//   } catch (err) {
//     console.log("addNews Error", err);
//     // notify("Error in addNews", "error");
//     return err;
//   }
// };

// export const updateNews = async (data, id) => {
//   try {
//     const response = await axios.put(`/news/news/${id}/`, data);
//     if (response.status === 200) {
//       console.log("updateNews Response_____", response);
//       return response.data;
//     }
//   } catch (err) {
//     console.log("updateNews Error", err);
//     // notify("Error in updateNews", "error");
//     return err;
//   }
// };

// export const deleteNews = async (id) => {
//   try {
//     const response = await axios.delete(`/news/news/${id}/`);
//     if (response.status === 204) {
//       console.log("deleteNews Response_____", response);
//       return true;
//     }
//   } catch (err) {
//     console.log("deleteNews Error", err);
//     // notify("Error in deleteNews", "error");
//     return err;
//   }
// };

////////////// ---- Contact news ---- /////////////

export const addContact = async (data) => {
  try {
    console.log(data);
    console.log(axios.defaults.baseURL);
    const response = await axios.post(`/contact/contact/`, data);
    if (response.status === 201) {
      console.log("addContact Response_____", response);
      notify("Message received successfully!", "success");
      return response.data;
    }
  } catch (err) {
    console.log("addContact Error", err);
    notify("Mesage could not be sent!", "error");
    return err;
  }
};

////////////// ---- Contact news ---- /////////////

export const fetchWallpapers = async () => {
  try {
    const response = await axios.get(`/wallpaper/wallpaper/`);
    if (response.status === 200) {
      console.log("fetchWallpapers Response_____", response);
      return response.data;
    }
  } catch (err) {
    console.log("fetchWallpapers Error", err);
    // notify("Error in fetchWallpapers", "error");
    return err;
  }
};
