import React, { useEffect, useState } from "react";
import { Container, Navbar, Offcanvas } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import {
  back_icon_circle,
  drone_logo,
  master_the_sky,
  master_the_sky_new,
  master_the_sky_png,
  menu,
  menu_open,
} from "../../assets";
import { SharpLine } from "../Site/SharpLine";

const navRoutes = [
  {
    title: "Home",
    path: "",
  },
  {
    title: "Updates",
    path: "updates",
  },
  {
    title: "About Us",
    path: "about-us",
  },
  {
    title: "Timeline",
    path: "timeline",
  },
  {
    title: "Wallpapers",
    path: "wallpapers",
  },
  {
    title: "Contact Us",
    path: "contact-us",
  },
];

const SiteNavbar = ({
  showLogo = true,
  showBackIcon,
  backAction,
  showToggleIcon = true,
  title,
  showVideo,
  showTitleOnLg,
  className,
  style,
}) => {
  const navigate = useNavigate();
  const xsmall = useMediaQuery({ query: "(max-width: 360px)" });
  const msmall = useMediaQuery({ query: "(min-width: 400px)" });
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });
  const xxlarge = useMediaQuery({ query: "(min-width: 1400px)" });

  const location = useLocation();
  const params = location.pathname.split("/").filter((item) => item !== "");
  const param1 = params.length > 0 ? params[0] : "";

  const [active, setActive] = useState("");
  const [open, setOpen] = useState(false);
  const [showActiveLine, setShowActiveLine] = useState(true);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleOffCanvasToggle = () => {
    if (open) {
      setOpen(false);
    } else setOpen(true);
  };

  useEffect(() => {
    setActive(param1);
  }, [param1]);

  return (
    <Navbar
      expand={false}
      className={`bg-transparent offcanvas-bg mb-3 p-0 py-md-2 ${className}`}
      style={{ minHeight: 76, ...style }}
    >
      <Container fluid className="p-0 position-relative">
        {showLogo ? (
          <Navbar.Brand
            href="/"
            className="d-flex justify-content-center justify-content-md-start align-items-center me-1 cursor-pointer w-100 text-center text-md-start"
            style={{ marginTop: medium ? 0 : 48 }}
          >
            <img
              src={drone_logo}
              alt="Master the Sky"
              className="img-fluid cursor-pointer me-3"
              style={{
                objectFit: "contain",
                // height: xlarge ? 78 : large ? 68 : medium ? 60 : 50,
                // width: "auto,",
                width: xsmall ? 46 : 54,
              }}
            />
            <img
              src={master_the_sky_new}
              alt="Master the Sky"
              className="img-fluid cursor-pointer"
              style={{
                objectFit: "contain",
                // height: xlarge ? 68 : large ? 56 : medium ? 60 : 50,
                // width: "auto,",
                width: xsmall ? 172 : 184,
              }}
            />
          </Navbar.Brand>
        ) : showBackIcon ? (
          <div
            className={`ms-2 ms-md-3 ms-lg-5 ${
              !showLogo && "mt-2 mt-md-3 mt-lg-4"
            }`}
            onClick={backAction}
            style={{ minWidth: 28 }}
          >
            <img
              src={back_icon_circle}
              alt="back"
              className="img-fluid w-100"
              style={{ minWidth: 28 }}
            />
          </div>
        ) : !large && title ? (
          <div className="d-flex d-lg-none align-items-center mt-2">
            <h2 className={`text-white ${xsmall ? "me-2" : "me-3"} me-sm-4`}>
              {title}
            </h2>
            <SharpLine
              toRight={true}
              style={{ width: medium ? 280 : small ? 200 : msmall ? 100 : 100 }}
            />
          </div>
        ) : (
          showTitleOnLg && (
            <div className="d-none d-lg-inline-block justify-content-center align-items-center ms-5 mt-4 me-2">
              <h1 className="text-white text-center mb-2">{title}</h1>
              <SharpLine style={{ width: medium ? 300 : small ? 250 : 120 }} />
            </div>
          )
        )}

        {!open && !showVideo && showToggleIcon && (
          // {!showVideo && showToggleIcon && (
          <Navbar.Toggle
            aria-controls="offcanvasNavbar-expand-false"
            className={`focus-none border-0 p-1 ms-auto ${
              !showLogo && "mt-2 mt-md-3 mt-lg-4"
            }`}
            style={{
              zIndex: medium ? 2000 : 1000,
              position: "absolute",
              right: 0,
              ...(large ? {} : showLogo && !title ? { top: "30%" } : {}),
            }}
            // style={{ zIndex: 2999 }}
            onClick={handleOffCanvasToggle}
          >
            <img
              src={large ? menu : menu_open}
              alt="menu"
              className="focus-none img-fluid"
              style={{ width: 20, height: 20 }}
            />
            {/* <CgMenu size={medium ? 28 : small ? 24 : 22} /> */}
          </Navbar.Toggle>
        )}
        <Navbar.Offcanvas
          id="offcanvasNavbar-expand-false"
          aria-labelledby="offcanvasNavbarLabel-expand-false"
          placement="end"
          onFocus={() => setOpen(true)}
          onBlur={() => setOpen(false)}
          show={open}
          className="bg-transparent text-white"
          style={{
            width: large ? 380 : medium ? 320 : small ? 300 : 280,
            backgroundImage:
              "linear-gradient(to right, rgba(0, 0, 0, 0),rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 1))",
          }}
        >
          <Offcanvas.Body>
            <div className="d-flex justify-content-end align-items-start py-2 py-md-3 me-md-3 me-lg-4 me-xl-4">
              <div>
                <div style={{ height: 100 }} className="" />
                <div className="d-flex flex-column align-items-end p-2 pt-5 me-4">
                  {navRoutes.map((route, i) => {
                    return (
                      <div
                        key={i}
                        className="d-flex flex-column align-items-end pb-2"
                        style={{ height: 66 }}
                        onClick={() => {
                          setActive(route.path);
                          navigate(`/${route.path}`);
                        }}
                        onMouseEnter={() => {
                          setShowActiveLine(false);
                          setHoveredIndex(i);
                        }}
                        onMouseLeave={() => {
                          setShowActiveLine(true);
                          setHoveredIndex(null);
                        }}
                      >
                        <div style={{ height: 32 }}>
                          <h3 className="ms-4 text-uppercase text-white mb-2 cursor-pointer">
                            {route.title}
                          </h3>
                          {active === route.path && showActiveLine && (
                            <SharpLine toLeft={true} className="w-100" />
                          )}
                          {i === hoveredIndex && (
                            <SharpLine toLeft={true} className="w-100" />
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="d-flex flex-column align-items-center">
                <div
                  onClick={handleOffCanvasToggle}
                  className="ms-auto text-center pt-2"
                  style={{ width: 40, height: 100 }}
                >
                  <img
                    src={large ? menu : menu}
                    alt="menu"
                    className="img-fluid"
                    style={{ width: 20, height: 20 }}
                  />
                </div>
                <SharpLine
                  isVertical={true}
                  style={{ height: medium ? 460 : 440 }}
                />
              </div>
            </div>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
};

export default SiteNavbar;
