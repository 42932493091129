import React from "react";

// export const SharpLine = ({
//   className,
//   style,
//   toLeft,
//   toRight,
//   toTop,
//   toBottom,
// }) => {
//   return (
//     <div
//       className={className}
//       style={{
//         minWidth: 100,
//         height: 2,
//         background: "rgb(242,242,242)",
//         background: toLeft
//           ? "linear-gradient(90deg, rgba(97,97,97,0.0788) 0%, rgba(255,255,255,1) 100%)"
//           : toRight
//           ? "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(97,97,97,0.0788) 100%)"
//           : toTop
//           ? ""
//           : toBottom
//           ? ""
//           : "radial-gradient(circle, rgba(242,242,242,1) 43%, rgba(97,97,97,0.090) 100%)",
//         ...style,
//       }}
//     />
//   );
// };

export const SharpLine = ({
  className,
  style,
  isVertical,
  toLeft,
  toRight,
  toTop,
  toBottom,
}) => {
  const sharpLineStyles = isVertical ? styles.vertical : styles.horizontal;

  return (
    <div
      className={className}
      style={{
        ...sharpLineStyles,
        background: "rgb(242,242,242)",
        background: toLeft
          ? "linear-gradient(90deg, rgba(97,97,97,0.0788) 0%, rgba(255,255,255,1) 100%)"
          : toRight
          ? "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(97,97,97,0.0788) 100%)"
          : toTop
          ? "linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(97,97,97,0.0788) 100%)"
          : toBottom
          ? "linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(97,97,97,0.0788) 100%)"
          : "radial-gradient(circle, rgba(242,242,242,1) 43%, rgba(97,97,97,0.090) 100%)",
        ...style,
      }}
    />
  );
};

const styles = {
  horizontal: {
    minWidth: 50,
    height: 2,
  },
  vertical: {
    minHeight: 50,
    width: 2,
  },
};
